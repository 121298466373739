import React from 'react';

import cx from 'classnames';

import {
  Heading,
  Paragraph,
  Button,
  Image,
  PhotoToVideoEmbed
} from 'components';

import firstSectionImage from './assets/first-section-image.png';
import thirdSectionImage from './assets/third-section-image.png';

import styles from './styles.module.scss';

const Line = ({
  subTitle,
  title,
  description,
  buttonText,
  buttonAction,
  reversed = false,
  image,
  withBlueBackground = false
}) => (
  <div
    className={cx(styles.wrapper, {
      [styles.withBlueBackground]: withBlueBackground
    })}>
    <div className={cx(styles.line, { [styles.reversed]: reversed })}>
      <div className={styles.left}>{image}</div>
      <div className={styles.right}>
        <div className={styles.content}>
          <Paragraph size="small" color="blue" className={styles.subTitle}>
            {subTitle}
          </Paragraph>
          <Heading size="small" className={styles.title}>
            {title}
          </Heading>
          {description && (
            <Paragraph size="medium" className={styles.description}>
              {description}
            </Paragraph>
          )}
          {buttonText && (
            <Button
              size="medium"
              borderColor="blue"
              textColor="white"
              backgroundColor="blue"
              className={styles.button}
              onClick={buttonAction}>
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  </div>
);

const ProductDescription = ({
  openBecomeASpeakerModal,
  openBecomeAPartnerModal
}) => (
  <div className={styles.container}>
    <Line
      reversed
      withBlueBackground
      image={
        <PhotoToVideoEmbed
          image={thirdSectionImage}
          imageClassName={styles.thirdSectionImage}
          video="https://vimeo.com/429648182"
          text="Watch Connect’s professional filming process"
        />
      }
      subTitle="Speaker Coaching & Green Screen"
      title="Filming in a green screen environment means you can use your imagination - no need to be limited by boring slide shows!"
      description="Our speakers receive coaching on speaking to camera and on the possibilities offered in a green screen environment. Use your imagination and wow the audience."
    />
    <div className={styles.logoHeading}>RE:Connect</div>

    <Heading size="medium" className={styles.heading}>
      A Hybrid Festival Celebrating and Exploring the Future of the Built
      Environment
    </Heading>
    <Paragraph className={styles.descriptionHeader}>January 2021</Paragraph>

    <div className={styles.irregularSection}>
      <div className={styles.topWrapper}>
        <div className={styles.top}>
          <Image
            width="100%"
            src={firstSectionImage}
            className={styles.firstSectionImage}
          />
        </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.content}>
          <div className={styles.left}>
            <Paragraph size="small" color="blue" className={styles.subTitle}>
              Create for thought leaders around the world
            </Paragraph>
            <Heading size="small" className={styles.title}>
              RE:Connect is an open platform where you can broadcast your
              pre-recorded or live-streamed content. You're in charge. You
              choose how to best contribute to the discussion.
            </Heading>
          </div>
          <div className={styles.right}>
            <Paragraph size="medium" className={styles.description}>
              Whether its a Zoom-based roundtable or a live walk-around of a
              project using GoPro, the opportunities are limited only by your
              imagination.
            </Paragraph>
          </div>
        </div>
        <Button
          size="medium"
          borderColor="blue"
          textColor="white"
          backgroundColor="blue"
          className={styles.button}
          onClick={openBecomeASpeakerModal}>
          Find out more
        </Button>
      </div>
    </div>
  </div>
);

export default ProductDescription;
